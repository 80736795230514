<template>
    <div class="chart-view">
        <div class="l-padded chart-view__datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="l-stack l-gap-2 chart-view__chart">
            <AssetChartsSettings
                :axes="settingsAxes"
                @change="handleSettingsChange"
            />

            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <template v-else>
                <AssetChartsStats
                    :colors="chartOptions.colors"
                    :series="series"
                    :units="units"
                />

                <ApexChart
                    ref="chart"
                    height="360px"
                    :options="chartOptionsComputed"
                    :series="series"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { debounce, merge, xor } from 'lodash'
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, measurementHelper } from '@/utils'
import AssetChartsSettings from '@/components/AssetChartsSettings'
import AssetChartsStats from '@/components/AssetChartsStats'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

const {
    VUE_APP_COLOR_PRIMARY: colorPrimary,
    VUE_APP_COLOR_ACCENT: colorAccent,
} = process.env

const voltageChartMockData = [
    {
        timestamp: '2021-11-30T15:30',
        sensor_data: {
            voltage_0: 7200,
            voltage_1: 7200,
            voltage_2: 6300,
            voltage_3: 2100,
            voltage_4: 12000,
        },
    },
    {
        timestamp: '2021-11-30T15:00',
        sensor_data: {
            voltage_0: 7000,
            voltage_1: 8000,
            voltage_2: 6000,
            voltage_3: 2000,
            voltage_4: 11000,
        },
    },
    {
        timestamp: '2021-11-30T14:30',
        sensor_data: {
            voltage_0: 8000,
            voltage_1: 8500,
            voltage_2: 7000,
            voltage_3: 4000,
            voltage_4: 11200,
        },
    },
    {
        timestamp: '2021-11-30T14:00',
        sensor_data: {
            voltage_0: 8400,
            voltage_1: 8200,
            voltage_2: 6000,
            voltage_3: 5100,
            voltage_4: 11300,
        },
    },
]

export default {
    name: 'AssetChartsCombinedView',
    components: {
        ApexChart,
        AssetChartsSettings,
        AssetChartsStats,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        simulated: {
            type: Boolean,
        },
        assetType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                },
                stroke: {
                    curve: 'straight',
                    width: 3,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: true,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            dataTypes: [],
            isLoading: false,
            series: [
                {
                    data: [],
                },
            ],
            yAxisBounds: [],
        }
    },
    computed: {
        chartOptionsComputed() {
            return merge(
                { ...this.chartOptions },
                {
                    colors: this.getColors(),
                    yaxis: this.isMultiYAxis
                        ? this.dataTypes.map((item, i) => ({
                              decimalsInFloat: 2,
                              title: {
                                  text: this.getYTitleSingle(item),
                              },
                              min: this.yAxisBounds[i]
                                  ? this.yAxisBounds[i][0]
                                  : this.getYMinValueSingle(item),
                              max: this.yAxisBounds[i]
                                  ? this.yAxisBounds[i][1]
                                  : this.getYMaxValueSingle(item),
                              opposite: this.isCM1Chart
                                  ? /humidity(_absolute)?/.test(item)
                                  : i % 2 === 0,
                          }))
                        : {
                              decimalsInFloat: 2,
                              title: {
                                  text: this.getYTitle(),
                              },
                              min: this.yAxisBounds[0]
                                  ? this.yAxisBounds[0][0]
                                  : this.getYMinValue(),
                              max: this.yAxisBounds[0]
                                  ? this.yAxisBounds[0][1]
                                  : this.getYMaxValue(),
                          },
                    ...(this.isFenceGuardVoltageChart && {
                        annotations: {
                            yaxis: [
                                {
                                    y: 3,
                                    borderColor: '#000',
                                    strokeDashArray: 3,
                                },
                            ],
                        },
                    }),
                }
            )
        },
        isClueyLeakageSensorsChart() {
            return (
                this.assetType === 'cluey-leakage' &&
                this.dataTypes.every(type => /^ai\d+$/.test(type))
            )
        },
        isCM1Chart() {
            return !xor(this.dataTypes, [
                'humidity',
                'humidity_absolute',
                'temperature',
            ]).length
        },
        isFenceGuardVoltageChart() {
            return (
                this.assetType === 'fence-guard' &&
                this.dataTypes.every(type => /^voltage_\d+$/.test(type))
            )
        },
        isSaltVehicleChart() {
            return this.assetType === 'salt'
        },
        isMultiYAxis() {
            return (
                this.isPrecipitationChart ||
                this.isCM1Chart ||
                !xor(this.dataTypes, ['humidity', 'temperature']).length
            )
        },
        isPrecipitationChart() {
            return this.dataTypes.every(type =>
                /^precipitation_(absolute|intensity)$/.test(type)
            )
        },
        isTemperatureChart() {
            return this.dataTypes.every(type => /^t\d$/.test(type))
        },
        settingsAxes() {
            return this.isMultiYAxis
                ? this.dataTypes.map(this.getYTitleSingle)
                : undefined
        },
        units() {
            return this.dataTypes.map(
                dataType => measurementHelper.units[dataType]
            )
        },
    },
    watch: {
        ['$route.query']() {
            if (this.$route.query.measurement?.length) {
                this.dataTypes = [].concat(this.$route.query.measurement)
                this.load()
            }
        },
        customRange() {
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
            dataTypes: {
                key: 'measurement',
                type: 'array',
            },
        })
    },
    methods: {
        getColors() {
            if (this.dataTypes.length > 2) {
                return this.chartOptions.colorsDefault
            }

            const defaultColors = [colorAccent, colorPrimary]
            return this.dataTypes.map((item, i) => {
                switch (item) {
                    case 'humidity':
                    case 'water_level':
                        return '#0077c2'
                    case 'temperature':
                        return colorAccent
                    default:
                        return defaultColors[i]
                }
            })
        },
        getConverter(dataType) {
            if (dataType === 'distance') {
                if (this.assetType === 'bin') {
                    return measurementHelper.convertToBinLevel
                } else if (this.assetType === 'sbb-bin') {
                    return measurementHelper.convertToSbbBinLevel
                }
            } else if (dataType === 'mass' && this.assetType === 'silo') {
                return measurementHelper.converters.silo_mass
            } else if (
                this.assetType === 'fence-guard' &&
                /^voltage_/.test(dataType)
            ) {
                return measurementHelper.converters.fence_voltage
            }

            return (
                measurementHelper.converters[dataType] ||
                measurementHelper.converters.default
            )
        },
        getYTitleSingle(dataType) {
            const key = `yaxis_${dataType}`
            return this.$te(key) ? this.$t(key) : dataType
        },
        getYTitle() {
            if (this.isClueyLeakageSensorsChart) {
                return this.$t('yaxis_clueyLeakage_ai')
            } else if (this.isFenceGuardVoltageChart) {
                return this.$t('yaxis_fenceGuard_voltage')
            } else if (this.isTemperatureChart) {
                return this.$t('yaxis_temperature')
            } else if (this.isSaltVehicleChart) {
                return this.$t('yaxis_spread_density_g_m2')
            }

            return this.dataTypes
                .map(this.getYTitleSingle)
                .join(` ${this.$t('and')} `)
        },
        getYMinValueSingle(dataType) {
            switch (dataType) {
                case 'temperature':
                    return -25
                case 'ai1':
                case 'ai2':
                case 'ai3':
                case 'ai4':
                case 'ai5':
                case 'ai6':
                case 'ai7':
                case 'ai8':
                case 'battery':
                case 'battery_voltage':
                case 'co2':
                case 'distance':
                case 'fill_level':
                case 'humidity':
                case 'mass':
                case 'power_0':
                case 'power_1':
                case 'power_2':
                case 'power_3':
                case 'precipitation_absolute':
                case 'precipitation_intensity':
                case 'voc':
                case 'voltage_0':
                case 'voltage_1':
                case 'voltage_2':
                case 'voltage_3':
                case 'voltage_4':
                case 'volume':
                    return 0
                case 'humidity_absolute':
                    return 5
                case 't1':
                case 't2':
                case 't3':
                case 't4':
                case 't5':
                case 't6':
                    return NaN // use min value
            }
        },
        getYMinValue() {
            const min = Math.min(...this.dataTypes.map(this.getYMinValueSingle))
            return Number.isNaN(min) ? undefined : min
        },
        getYMaxValueSingle(dataType) {
            switch (dataType) {
                case 'voltage_0':
                case 'voltage_1':
                case 'voltage_2':
                case 'voltage_3':
                case 'voltage_4':
                    if (this.assetType === 'fence-guard') {
                        return 20
                    }
                    break
                case 'humidity_absolute':
                    return 30
                case 'precipitation_intensity':
                    return 35
                case 'temperature':
                    return 50
                case 'battery':
                case 'fill_level':
                case 'humidity':
                    return 100
                case 'precipitation_absolute':
                    return 300
                case 'power_0':
                case 'power_1':
                case 'power_2':
                case 'power_3':
                    return 3000
                case 'ai1':
                case 'ai2':
                case 'ai3':
                case 'ai4':
                case 'ai5':
                case 'ai6':
                case 'ai7':
                case 'ai8':
                    return 13000
                case 't1':
                case 't2':
                case 't3':
                case 't4':
                case 't5':
                case 't6':
                    return NaN // use max value
            }
        },
        getYMaxValue() {
            const max = Math.max(...this.dataTypes.map(this.getYMaxValueSingle))
            return Number.isNaN(max) ? undefined : max
        },
        handleSettingsChange(...args) {
            this.yAxisBounds = (Array.isArray(args[0]) ? args[0] : [args]).map(
                ([mode, min, max]) => {
                    switch (mode) {
                        case 'auto':
                        case 'custom':
                            return [min, max]
                    }
                }
            )
        },
        load: debounce(async function() {
            this.isLoading = true
            const results = this.simulated
                ? voltageChartMockData
                : await this.loadData()
            this.setData(results)
            this.isLoading = false
        }),
        async loadData() {
            let results = []
            let url =
                'measurements/?' +
                `tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&timestamp_min=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            return results
        },
        setData(results) {
            const chartData = this.dataTypes.map(dataType => {
                if (dataType === 'humidity_absolute') {
                    const data = results.filter(
                        item =>
                            Object.prototype.hasOwnProperty.call(
                                item.sensor_data,
                                'humidity'
                            ) &&
                            Object.prototype.hasOwnProperty.call(
                                item.sensor_data,
                                'temperature'
                            )
                    )
                    return data.map(item => [
                        item.timestamp,
                        measurementHelper.calculateAbsoluteHumidity(
                            item.sensor_data.humidity,
                            item.sensor_data.temperature
                        ),
                    ])
                }

                const converter = this.getConverter(dataType)
                const data = results.filter(item =>
                    Object.prototype.hasOwnProperty.call(
                        item.sensor_data,
                        dataType
                    )
                )
                return data.map(item => [
                    item.timestamp,
                    converter(item.sensor_data[dataType]),
                ])
            })

            this.series = chartData.map((data, i) => ({
                key: this.dataTypes[i],
                name: this.translateWithPrefix('router.', this.dataTypes[i]),
                data,
            }))
        },
        translateWithPrefix(prefix, dataType) {
            let assetTypePrefix = ''
            switch (this.assetType) {
                case 'cluey-leakage':
                    assetTypePrefix = 'clueyLeakage_'
                    break
                case 'fence-guard':
                    assetTypePrefix = 'fenceGuard_'
                    break
            }
            return this.$te(`${prefix}${assetTypePrefix}${dataType}`) ||
                this.$root.$te(`${prefix}${assetTypePrefix}${dataType}`)
                ? this.$t(`${prefix}${assetTypePrefix}${dataType}`)
                : this.$te(`${prefix}${dataType}`)
                ? this.$t(`${prefix}${dataType}`)
                : dataType
        },
    },
}
</script>

<i18n>
{
    "en": {
        "and": "and",
        "yaxis_battery": "Battery (%)",
        "yaxis_battery_voltage": "Battery voltage (V)",
        "yaxis_clueyLeakage_ai": "Sensors (Moser)",
        "yaxis_co2": "Carbon dioxide (ppm)",
        "yaxis_distance": "Distance (m)",
        "yaxis_fenceGuard_voltage": "Voltage (kV)",
        "yaxis_fillLevel": "Fill level (%)",
        "yaxis_fill_level": "Fill level (%)",
        "yaxis_humidity": "Humidity (%)",
        "yaxis_humidity_absolute": "Absolute humidity (g/m³)",
        "yaxis_lower_loop": "Lower loop",
        "yaxis_mass": "Mass (t)",
        "yaxis_mass_kg": "Mass (kg)",
        "yaxis_spread_density_g_m2": "Spread density (g/m2)",
        "yaxis_potential": "Potential",
        "yaxis_power_0": "L0",
        "yaxis_power_1": "L1",
        "yaxis_power_2": "L2",
        "yaxis_power_3": "L3",
        "yaxis_precipitation_absolute": "Precipitation absolute (mm)",
        "yaxis_precipitation_intensity": "Precipitation intensity (mm/h)",
        "yaxis_tau": "Tau",
        "yaxis_t1": "Temperature (°C)",
        "yaxis_t2": "Temperature (°C)",
        "yaxis_t3": "Temperature (°C)",
        "yaxis_t4": "Temperature (°C)",
        "yaxis_t5": "Temperature (°C)",
        "yaxis_t6": "Temperature (°C)",
        "yaxis_temperature": "Temperature (°C)",
        "yaxis_upper_loop": "Upper loop",
        "yaxis_voc": "Volatile organic compounds (ppb)",
        "yaxis_voltage": "Voltage (V)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Water level"
    },
    "de": {
        "and": "und",
        "yaxis_battery": "Batterie (%)",
        "yaxis_battery_voltage": "Batteriespannung (V)",
        "yaxis_clueyLeakage_ai": "Sensors (Moser)",
        "yaxis_co2": "Kohlenstoffdioxid (ppm)",
        "yaxis_distance": "Distanz (m)",
        "yaxis_fenceGuard_voltage": "Voltage (kV)",
        "yaxis_fillLevel": "Füllstand (%)",
        "yaxis_fill_level": "Füllstand (%)",
        "yaxis_humidity": "Luftfeuchtigkeit (%)",
        "yaxis_humidity_absolute": "Absolute Luftfeuchtigkeit (g/m³)",
        "yaxis_lower_loop": "Untere Schlaufe",
        "yaxis_mass": "Masse (t)",
        "yaxis_mass_kg": "Masse (kg)",
        "yaxis_spread_density_g_m2": "Streudichte (g/m2)",
        "yaxis_potential": "Potential",
        "yaxis_power_0": "L0",
        "yaxis_power_1": "L1",
        "yaxis_power_2": "L2",
        "yaxis_power_3": "L3",
        "yaxis_precipitation_absolute": "Niederschlag absolut (mm)",
        "yaxis_precipitation_intensity": "Niederschlagsintensität (mm/h)",
        "yaxis_tau": "Tau",
        "yaxis_t1": "Temperatur (°C)",
        "yaxis_t2": "Temperatur (°C)",
        "yaxis_t3": "Temperatur (°C)",
        "yaxis_t4": "Temperatur (°C)",
        "yaxis_t5": "Temperatur (°C)",
        "yaxis_t6": "Temperatur (°C)",
        "yaxis_temperature": "Temperatur (°C)",
        "yaxis_upper_loop": "Obere Schlaufe",
        "yaxis_voc": "Flüchtige organische Verbindungen (ppb)",
        "yaxis_voltage": "Spannung (V)",
        "yaxis_volume": "Volumen (m³)",
        "yaxis_water_level": "Wasserpegel"
    },
    "fr": {
        "and": "et",
        "yaxis_battery": "Batterie (%)",
        "yaxis_battery_voltage": "Tension de la batterie (V)",
        "yaxis_clueyLeakage_ai": "Sensors (Moser)",
        "yaxis_co2": "Dioxyde de carbone (ppm)",
        "yaxis_distance": "Distance (m)",
        "yaxis_fenceGuard_voltage": "Voltage (kV)",
        "yaxis_fillLevel": "Niveau de remplissage (%)",
        "yaxis_fill_level": "Niveau de remplissage (%)",
        "yaxis_humidity": "Humidité (%)",
        "yaxis_humidity_absolute": "Humidité absolue (g/m³)",
        "yaxis_lower_loop": "Boucle inférieure",
        "yaxis_mass": "Masse (t)",
        "yaxis_mass_kg": "Masse (kg)",
        "yaxis_spread_density_g_m2": "Densité de l'épandage (g/m2)",
        "yaxis_potential": "Potentiel",
        "yaxis_power_0": "L0",
        "yaxis_power_1": "L1",
        "yaxis_power_2": "L2",
        "yaxis_power_3": "L3",
        "yaxis_precipitation_absolute": "Précipitations absolues (mm)",
        "yaxis_precipitation_intensity": "Intensité des précipitations (mm/h)",
        "yaxis_tau": "Tau",
        "yaxis_t1": "Température (°C)",
        "yaxis_t2": "Température (°C)",
        "yaxis_t3": "Température (°C)",
        "yaxis_t4": "Température (°C)",
        "yaxis_t5": "Température (°C)",
        "yaxis_t6": "Température (°C)",
        "yaxis_temperature": "Température (°C)",
        "yaxis_upper_loop": "Boucle supérieure",
        "yaxis_voc": "Composants organiques volatiles (ppb)",
        "yaxis_voltage": "Tension (V)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Niveau de l'eau"
    },
    "it": {
        "and": "e",
        "yaxis_battery": "Batteria (%)",
        "yaxis_battery_voltage": "Tensione della batteria (V)",
        "yaxis_clueyLeakage_ai": "Sensors (Moser)",
        "yaxis_co2": "Anidride carbonica (ppm)",
        "yaxis_distance": "Distanza (m)",
        "yaxis_fenceGuard_voltage": "Voltage (kV)",
        "yaxis_fillLevel": "Riempimento (%)",
        "yaxis_fill_level": "Riempimento (%)",
        "yaxis_humidity": "Umidità (%)",
        "yaxis_humidity_absolute": "Umidità assoluta (g/m³)",
        "yaxis_lower_loop": "Loop inferiore",
        "yaxis_mass": "Massa (t)",
        "yaxis_mass_kg": "Massa (kg)",
        "yaxis_spread_density_g_m2": "Densità di spargimento (g/m2)",
        "yaxis_potential": "Potential",
        "yaxis_power_0": "L0",
        "yaxis_power_1": "L1",
        "yaxis_power_2": "L2",
        "yaxis_power_3": "L3",
        "yaxis_precipitation_absolute": "Precipitazioni assolute (mm)",
        "yaxis_precipitation_intensity": "Intensità delle precipitazioni (mm/h)",
        "yaxis_tau": "Tau",
        "yaxis_t1": "Temperatura (°C)",
        "yaxis_t2": "Temperatura (°C)",
        "yaxis_t3": "Temperatura (°C)",
        "yaxis_t4": "Temperatura (°C)",
        "yaxis_t5": "Temperatura (°C)",
        "yaxis_t6": "Temperatura (°C)",
        "yaxis_temperature": "Temperatura (°C)",
        "yaxis_upper_loop": "Loop superiore",
        "yaxis_voc": "Composti organici volatili (ppb)",
        "yaxis_voltage": "Voltaggio (V)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Livello dell'acqua"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-view {
    display: flex;

    &__chart {
        flex-grow: 100;
        padding: 1rem;
        border-left: $style-border;
        overflow-x: hidden;
    }

    &__datepicker {
        width: 20%;
        min-width: 350px;
    }

    @include respond-to('for-tablet-down') {
        display: block;

        &__chart {
            border: none;
        }

        &__datepicker {
            padding: 1rem;
            width: auto;
            min-width: auto;
            border-bottom: $style-border;
        }
    }
}
</style>
